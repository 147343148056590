import React, {useEffect, useState} from 'react';

import {
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Paper,
  Stack,
  Typography
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import TabPanel from '../../../components/price/TabPanel';
import PriceBox from '../../../components/price/PriceBox';

const OfficePriceList = [
    {
      "range" : "1개월",
      "original" : "300,000",
      "price" : "300,000",
      "discount" : 0,
      "monthly" : "300,000"
    },
    {
      "range" : "3개월",
      "price" : "810,000",
      "original" : "900,000",
      "discount" : 10,
      "monthly" : "270,000"
    },
    {
      "range" : "6개월",
      "price" : "1,500,000",
      "original" : "1,800,000",
      "discount" : 16.7,
      "monthly" : "250,000"
    },
    {
      "range" : "12개월",
      "price" : "2,760,000",
      "original" : "3,600,000",
      "discount" : 23.3,
      "monthly" : "230,000"
    }
];

function FirstRentPanel(props){
  return (
    <TabPanel value={props.tab} index={props.index}>
      <Typography
        sx={{
          fontFamily: "NotoSansKR",
          display:'block',
          fontWeight:700,
          fontSize:'30px',
          mb : '24px',
          textAlign:'center',
          color:blueGrey[800]
        }}>
        오픈형 좌석
      </Typography>
      <Typography
        sx={{
          fontFamily: "NotoSansKR",
          display:'block',
          fontWeight:500,
          fontSize:'18px',
          mb : '32px',
          textAlign:'center',
          color:blueGrey[500]
        }}>
        오픈형 좌석은 넓은 공간에서 파티션으로 구분된 개인 자리를 제공합니다.
        막혀있는 공간이 답단한 스타트업 사업자나, 추후 확장성이 있는 사업자에게 적합합니다.
      </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{mb:'12px'}}
        spacing={2}>
        {OfficePriceList.map((el, index) => (
          <PriceBox
            onMouseEnter={props.handlePopoverOpen(index)}
            onMouseLeave={props.handlePopoverClose}
            value={el}
            hover={props.hoverElement === index}
            />
        ))}
      </Stack>
      <Typography
        sx={{
          fontFamily: "NotoSansKR",
          display:'block',
          fontWeight:700,
          fontSize:'14px',
          textAlign:'center',
          color:blueGrey[400]
        }}>
        * VAT는 별도이며, 해당 가격 할인 이벤트는 종료될 수 있습니다.
      </Typography>
    </TabPanel>
  );
}

export default FirstRentPanel;
