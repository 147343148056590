import React from 'react';

import Typography  from '@mui/material/Typography';

export default function TH3(props){
  return (
    <Typography
      sx={{
        ...props.sx,
        fontFamily: "NotoSansKR",
        fontWeight:500,
        fontSize:'16px',
        textAlign:'left',
        color:'#333333'
      }}>
      {props.children}
    </Typography>
  );
}
