import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Container,
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Paper,
  Stack,
  Typography
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import MyReservationView from '../../../components/app/meeting/MyReservationView';
import MeetingRoomView from '../../../components/app/meeting/MeetingRoomView';
import MemberTableView from '../../../components/app/setting/MemberTableView';
import axios from "axios";

function SettingPage(props){

  const [myInfo, setMyInfo] = useState({
    name : "-",
    email : "-",
    number : "-",
    lastVisit : "-",
  });

  const [myCompany, setMyCompany] = useState({
    name : "-",
    register : "-",
    email : "-",
    number : "-",
    items : ["-"],
  });

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative',  boxSizing:'border-box' }}>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Typography variant="h6" element="p" sx={{
          textAlign:"left",
          fontFamily: "NotoSansKR",
          fontWeight:700,
          position:'relative',
          mb:'24px'}}>
          나의 정보
        </Typography>
        <Box sx={{position:'relative', width:'100%'}}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={2}
            alignItems="flex-start">
            <Grid
              item
              md={6}>
              <Paper
                elevation={4}
                sx={{
                  borderRadius:'16px',
                  width:'100%',
                  p:2}}>
                <Typography element="p" sx={{
                  textAlign:"left",
                  fontFamily: "NotoSansKR",
                  textSize:'20px',
                  fontWeight:700,
                  mb:'12px'}}>
                  나의 정보
                </Typography>
                <Box
                  sx={{
                    height:'2px',
                    width:'32px',
                    borderRadius:'2px',
                    mb:'12px',
                    backgroundColor:blue[500]
                  }}/>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        이름
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        {myInfo.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        이메일
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        {myInfo.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        마지막 로그인
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        {myInfo.lastVisit}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        비밀번호
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            fontFamily: "NotoSansKR",
                            textSize:'16px',
                            fontWeight:700
                          }}>
                         비밀번호 변경
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              </Paper>
            </Grid>
            <Grid
              item
              md={6}>
              <Paper
                elevation={4}
                sx={{
                  borderRadius:'16px',
                  width:'100%',
                  p:2}}>
                <Typography element="p" sx={{
                  textAlign:"left",
                  fontFamily: "NotoSansKR",
                  textSize:'18px',
                  fontWeight:700,
                  mb:'16px'}}>
                  회사 정보
                </Typography>
                <Box
                  sx={{
                    height:'2px',
                    width:'32px',
                    borderRadius:'2px',
                    mb:'12px',
                    backgroundColor:blue[500]
                  }}/>
                <Stack
                  sx={{width:'100%'}}
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        회사명
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        {myCompany.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        사업자 번호
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        {myCompany.register}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        대표 이메일
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        {myCompany.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        대표 전화번호
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        {myCompany.number}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        사업 아이템
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        {myCompany.items[0]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              </Paper>
            </Grid>
            <Grid
              item
              md={12}>
              <Box sx={{width:'100%', height:'16px'}} />
              <Typography variant="h6" element="p" sx={{
                textAlign:"left",
                fontFamily: "NotoSansKR",
                fontWeight:700,
                position:'relative',
                mb:'24px'}}>
                회사 구성원
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}>
                <Grid
                  item
                  md={12}>
                  <MemberTableView />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default SettingPage;
