import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import { teal } from '@mui/material/colors';

import {
  Button,
  IconButton,
  Grid
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey
} from '@mui/material/colors';


import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import HowtoAccordion from '../../components/howto/HowtoAccordion';

import axios from "axios";

const HowtoList = [
    {
      id:1,
      q:"긱토 스페이스는 어떤 곳인가요?",
      a:"우선 공유 오피스란 사무실 임대보다 부담이 적어 개인 창업, 프리랜서 등 소규모 사업자의 비용(관리비, 보증금 등)을 절약 하고자 만든 임대 시스템입니다. \
      \n 저희 (긱토) 오피스는 타 브랜드 공유 오피스에서 볼 수 없는 넓은 공간과 다양한 인프라를 통해 최고의 업무 효율을 낼 수 있는 공유 오피스입니다."
    },
    {
      id:2,
      q:"계약 후 바로 입주가 가능한가요?",
      a:"네 가능합니다.\
      \n 단, 입주사의 쾌적한 업무 환경에 피해를 주거나 이용자의 지장을 주는 일부 업종이라고 판단시 입주가 제한 될 수 있습니다."
    },
    {
      id:3,
      q:"중도퇴실 시 환불은 어떻게 될까요?",
      a:"계약된 기간 내에 중도 퇴실 시 환불은 어렵습니다."
    },
    {
      id:4,
      q:"입주금 외에 제반 비용은 얼마인가요?",
      a:"저희 (긱토) 오피스에서는 관리비, 냉난방비, 전기료가 포함되어 추가 발생하는 비용은 없습니다.\
      \n 단, 입주 공간 외의 회의실, 복합기 사용에 있어서는 금액이 발생할 수 있습니다."
    },
    {
      id:5,
      q:"사업자 등록 및 주소지 사용이 가능한가요?",
      a:"3개월 이상 계약 시, 가능합니다.\
      \n 분리형 사무실과 오픈 데스크 형태에 상관없이 법인 설립을 위한 사업자 등록,주소지 이전까지 가능합니다."
    },
    {
      id:6,
      q:"방문객은 어떻게 출입 하나요",
      a:"방문객은 입주사의 동행하에 안전하고 업무 환경에 피해를 주지 않는 범위내에서 이용이 가능합니다."
    },
    {
      id:7,
      q:"주차는 가능한가요?",
      a:"네, 가능합니다. (별도의 주차 비용 발생 가능)\
      \n 지하 1층 ~ 4층까지 넓은 주차장을 이용할 수 있습니다."
    },
    {
      id:8,
      q:"긱토 스페이스 이용시간은 어떻게 되나요?",
      a:"평일, 주말 상관 없이 (긱토)오피스를 24시간 언제나 이용이 가능합니다.\
      \n 단, 예약제로 운영 되는 공간(회의실 등)의 경우 예약자가 있을 시 이용이 제한 될 수 있습니다."
    },
    {
      id:9,
      q:"복합기 이용 가능한가요?",
      a:"네 가능합니다.\
      \n 지급된 포인트 내에서 가능합니다."
    },
    {
      id:10,
      q:"방문 상담을 원하는 경우 어떻게 하나요?",
      a:"언제든지 상담 채널(카카오톡)을 통해 담당자를 통해 일정 예약과 친절한 상담을 해드리고 있습니다."
    },
    {
      id:11,
      q:"[비상주 오피스] 비상주 오피스가 무엇인가요?",
      a:"사업자 등록에 필요한 주소지 등을 제공하는 서비스입니다."
    },
    {
      id:12,
      q:"[비상주 오피스] 어떤 사람에게 필요 할까요?",
      a:"개인이나 법인 사업자 등록을 할 수 있는 주소지가 필요한 창업자 이거나 사무실 임대 비용을 절약 하고 싶으신 분, \
      \n 이미 사업장이 있지만 다른 주소지 사업자 등록이 필요하신 분 또는 재택 근무하면서 사업자 등록을 위해 주소지가 필요한 사업자분들을 위한 서비스입니다."
    },
    {
      id:13,
      q:"[비상주 오피스] 부가 서비스는 어떤것이 있을까요?",
      a:"우편물 관리, 실사 지원 모두 가능합니다."
    },
    {
      id:14,
      q:"[비상주 오피스] 상담은 어떻게 진행이 될까요?",
      a:"언제든지 상담 채널(카카오톡)을 통해 담당자를 통해 진행 방법과 절차를 친절하게 안내 받으실 수 있습니다."
    }
];

function HowtoPage(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Header {...props} />
      <Box sx={{height:'auto', backgroundColor:'black'}}>
        <Box sx={{width:'100%', height:'60px'}} />
        <Toolbar sx={{position:'relative', height:'100%'}}>
        </Toolbar>
      </Box>
      <Container>
        <Box sx={{ my: 2 }}>
          <Box sx={{width:'100%', height:'120px'}} />
          <Grid container spacing={1}
            direction="row"
            justifyContent="center"
            sx={{minHeight:'100vh'}}
            alignItems="flex-start">
            <Grid item md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:700,
                  textAlign:'left',
                  color:lightBlue[600]
                }}>
                자주 묻는 질문
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:700,
                  fontSize:'24px',
                  textAlign:'left',
                  mb:'24px',
                  color:blueGrey[800]
                }}>
                궁금한 지점이 있다면, 여기서 빠르게 확인하세요
              </Typography>
            </Grid>
            <Grid item md={12}>
            {HowtoList.map((data, index) => (
              <HowtoAccordion
                {...data}/>
            ))}
            </Grid>
          </Grid>
          <Box sx={{width:'100%', height:'120px'}} />
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

export default HowtoPage;
