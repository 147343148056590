import React, {useEffect} from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';

import Typography  from '@mui/material/Typography';
import Button  from '@mui/material/Button';

import axios from "axios";

function LoginPage(props){

  const [values, setValues] = React.useState({
    id: '',
    password: '',
    showPassword: false,
  });

  const [viewErrors, setViewErrors] = React.useState({
    id: false,
    password: false
  });

  const handleChange = (prop) => (event) => {
    handleViewErrors("id", false);
    handleViewErrors("password", false);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleViewErrors = (prop, error) => {
    setViewErrors({ ...viewErrors, [prop]: error });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter' && e.target.value) {
      handleSubmit();
    }
  };

  const handleForgotAccount = (event) => {

  };

  const handleSignup = (event) => {

  };

  const handleSubmit = () => {
    let valid = true;
    if(values.id.length === 0){
      handleViewErrors("id", true);
      valid = false;
    }
    if(values.password.length === 0){
      handleViewErrors("password", true);
      valid = false;
    }
    if(!valid) return;
    props.setToken("1111");
    //requestLogin();
  };

  function requestLogin() {
     axios({
       method: "POST",
       url:"/api/token",
       data:{
         id: values.id,
         password: values.password
        }
     })
     .then((response) => {
       props.setToken(response.data.access_token)
     }).catch((error) => {
       if (error.response) {
         console.log(error.response)
         console.log(error.response.status)
         console.log(error.response.headers)
         }
     });
  }
  return (
    <>
      <Container
        sx={{
          height:"100vh",
          maxWidth:"100vw !important",
          backgroundColor:"rgba(0,0,0,.5)"
        }}>
        <img
          style={{
            position:'fixed',
            top:0,
            left:0,
            right:0,
            bottom:0,
            zIndex:-1,
            width:'100%',
            height:'100%'}}
          src={`/assets/image/auth-background.jpg`}
          srcSet={`/assets/image/auth-background.jpg`}
          loading="lazy"/>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            height:"100vh"
          }}>
          <Paper
            elevation={2}
            sx={{
              width:'360px',
              padding:'36px 24px',
              backgroundColor:"#fff",
              borderRadius:'16px'
            }}>
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center">
              <img
                style={{
                  marginBottom:'24px',
                  height:'84px'}}
                src={`/assets/icons/gigto_space_black_logo.png`}
                srcSet={`/assets/icons/gigto_space_black_logo.png`}
                loading="lazy"/>
              <TextField
                id="outlined-password-input"
                label="이메일"
                error={viewErrors.id}
                sx={{
                  width:"100%",
                  maxWidth:"250px",
                  borderRadius:'24px',
                }}
                helperText={viewErrors.id ? "이메일을 입력해주세요" : ""}
                onKeyDown={handleKeyDown}
                onChange={handleChange('id')}
                InputProps={{ sx: { borderRadius: '24px', height:'48px' } }}
                autoComplete="off"/>
              <FormControl sx={{
                  width:"100%",
                  maxWidth:"250px"
                }} variant="outlined"
                error={viewErrors.password}>
                <InputLabel htmlFor="outlined-adornment-password">패스워드</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  aria-describedby="component-error-text"
                  onKeyDown={handleKeyDown}
                  onChange={handleChange('password')}
                  sx={{borderRadius:'24px', height:'48px'}}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {viewErrors.password && (<FormHelperText id="component-error-text">패스워드를 입력해주세요</FormHelperText>)}
              </FormControl>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                  width:"100%",
                  maxWidth:"250px"}}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    width:'100%',
                    height:'42px',
                    borderRadius:'24px'
                  }}
                  onClick={handleSubmit}>로그인</Button>
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-end"
                spacing={0}
                sx={{
                  mt : "16px !important",
                  width:"100%",
                  maxWidth:"250px"}}>
                <Link href="/auth/findAccount" sx={{textDecoration:'none'}}>
                  <Button
                    size="small"
                    onClick={handleForgotAccount}
                    sx={{fontSize:"12px", color:"#888"}}
                    variant="text">비밀번호를 잊어버렸어요</Button>
                </Link>
                <Link href="/auth/signup" sx={{textDecoration:'none'}}>
                  <Button
                    size="small"
                    onClick={handleSignup}
                    sx={{fontSize:"12px", color:"#888"}}
                    variant="text">회원가입하기</Button>
                </Link>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </>
  );
}

export default LoginPage;
