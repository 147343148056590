import React, {useEffect, useState} from 'react';

import {
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Paper,
  Typography,
  Stack
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal,
  red
} from '@mui/material/colors';

function PriceBox(props){
  return (
    <Paper
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      elevation={props.hover ? 4 : 0}
      sx={{
        borderRadius:'12px',
        backgroundColor:"#ffffff",
        height:'330px',
        width:'220px',
        position:'relative',
        display:'block',
        p:3}}>
      <Grid
        container
        sx={{
          height:'100%',
          position:'relative'
        }}
        direction="column"
        justifyContent="center"
        alignItems="flex-start">
        <Grid item xs="auto">
          <Typography
            sx={{
              fontFamily: "NotoSansKR",
              fontWeight:700,
              textAlign:'left',
              fontSize:'24px',
              mb:'24px',
              color:blueGrey[800]
            }}>
            {props.value.range}
          </Typography>
        </Grid>
        <Grid item xs>
        </Grid>
        <Grid item xs="auto">
          {(props.value.price !== props.value.original) &&
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                fontSize:'14px',
                textAlign:'left',
                textDecoration:'line-through',
                color:blueGrey[500]
              }}>
              {props.value.original}원
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:700,
                  fontSize:'14px',
                  textAlign:'left',
                  color:red[500]
                }}>
                ▼ {props.value.discount} %
              </Typography>
            </Stack>}
          <Typography
            sx={{
              fontFamily: "NotoSansKR",
              fontWeight:700,
              fontSize:'24px',
              textAlign:'left',
              color:blueGrey[800]
            }}>
            {props.value.price}원
          </Typography>
          <Typography
            sx={{
              fontFamily: "NotoSansKR",
              fontWeight:700,
              fontSize:'14px',
              textAlign:'left',
              color:blueGrey[400]
            }}>
            {props.value.monthly} / 월
          </Typography>
        </Grid>
      </Grid>
      {props.value.range === "12개월" && <div class="ribbon">BEST</div>}
    </Paper>
  );
}

export default PriceBox;
