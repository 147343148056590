import * as React from 'react';
import {
  Chip,
  Stack,
  Typography
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import DoneOutlineTwoToneIcon from '@mui/icons-material/DoneOutlineTwoTone';

export default function MyReservationView(props) {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}>
      <Chip icon={props.reserve ? <HourglassFullTwoToneIcon /> : <DoneOutlineTwoToneIcon />}
      label={props.type} />
      <Typography
        sx={{
          fontFamily: "NotoSansKR",
          fontWeight:400,
          fontSize:'15px',
          textAlign:'left',
          color:blueGrey[800]
        }}>
        {props.time} | {props.people}명
      </Typography>
    </Stack>
  );
}
