import GridViewIcon from '@mui/icons-material/GridView';

import {
    CogOutlineIcon, PlusThickIcon,
    AutoFixIcon, BasketOutlineIcon,
    BellOutlineIcon, CurrencyUsdIcon,
    FinanceIcon, NecklaceIcon,
    PartyPopperIcon, StarIcon
} from '../../resources/icons';

import MeetingRoomTwoToneIcon from '@mui/icons-material/MeetingRoomTwoTone';
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';
import EventSeatTwoToneIcon from '@mui/icons-material/EventSeatTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import ExtensionTwoToneIcon from '@mui/icons-material/ExtensionTwoTone';

const MainNavItems = [
    {
      key : 'dashboard',
      title : '대시보드',
      type : 'link',
      to : '/u/dashboard',
      icon : <GridViewIcon sx={{color:'white'}} color="white" />
    },
    {
      key : 'contract',
      title : '오피스',
      type : 'link',
      to : '/u/office',
      icon : <EventSeatTwoToneIcon sx={{color:'white'}} color="white" />
    },
    {
      key : 'meetingRoom',
      title : '회의실',
      type : 'link',
      to : '/u/meeting',
      icon : <MeetingRoomTwoToneIcon sx={{color:'white'}} color="white" />
    },
    {
      key : 'service',
      title : '서비스',
      type : 'link',
      to : '/u/service',
      icon : <ExtensionTwoToneIcon sx={{color:'white'}} color="white" />
    },
    {
      key : 'setting',
      title : '나의 정보',
      type : 'link',
      to : '/u/setting',
      icon : <SettingsTwoToneIcon sx={{color:'white'}} color="white" />
    },
  ];

  export default MainNavItems;
