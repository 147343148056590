import React, {useEffect, useState} from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import {
  Container,
  Grid,
  Box,
  Typography
} from '@mui/material';

import TH1 from '../../components/common/TH1';
import TH2 from '../../components/common/TH2';
import TH3 from '../../components/common/TH3';
import TH4 from '../../components/common/TH4';


export default function PrivacyPage(props){
  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <Box sx={{my : 2}}>
          <TH1 sx={{mb:'24px'}}>개인정보보호</TH1>
          <TH2 sx={{mb:'6px'}}>"긱토 스페이스"은(는) (이하 "회사"는) 고객님의 개인정보를 중요시하며, "정보통신망 이용 촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.</TH2>
          <TH2 sx={{mb:'6px'}}>회사는 개인정보 취급 방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.</TH2>
          <TH2 sx={{mb:'6px'}}>회사는 개인정보 취급 방침을 개정하는 경우 웹사이트 공지 사항(또는 개별 공지)을 통하여 공지할 것입니다.</TH2>
          <TH2 sx={{mb:'6px'}}>개인정보 취급 방침 공고일 : 2024년 04월 01일</TH2>
          <TH2 sx={{mt:'12px',mb:'6px'}}>본 방침은 : [ 2024년 04월 01일 ]부터 시행됩니다.</TH2>
          <TH1 sx={{mt:'20px', mb:'24px'}}>개인정보 수집에 대한 동의</TH1>\\
          <TH3 sx={{mb:'6px'}}>회사는 귀하께서 회사의 개인정보보호 방침 또는 이용약관의 내용에 대해 「동의한다」버튼 또는 「동의하지 않는다」 버튼을 클릭할 수 있는 절차를 마련하여, 「동의한다」 버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.</TH3>
          <TH1 sx={{mt:'20px', mb:'24px'}}>아동의 개인정보보호</TH1>
          <TH3 sx={{mb:'6px'}}>회사는 만 14세 미만 아동의 개인정보를 수집하는 경우 법정대리인의 동의를 받습니다.</TH3>
          <TH3 sx={{mb:'6px'}}>만 14세 미만 아동의 법정대리인은 아동의 개인정보의 열람, 정정, 동의 철회를 요청할 수 있으며, 이러한 요청이 있을 경우 회사는 바로 필요한 조치를 취합니다.</TH3>
          <TH1 sx={{mt:'20px', mb:'24px'}}>수집하는 개인정보의 항목</TH1>
          <TH3 sx={{mb:'6px'}}>회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.</TH3>
          <TH3 sx={{mb:'6px'}}>수집 항목 : 이름 , 생년월일 , 성별 , 로그인ID , 비밀번호 , 자택 전화번호 , 자택 주소 , 휴대전화번호 , 이메일 , 직업 , 결혼여부 , 주민등록번호 , 서비스 이용기록 , 접속 로그 , 쿠키 , 접속 IP 정보 , 결제기록</TH3>
          <TH3 sx={{mb:'6px'}}>개인정보 수집 방법 : 홈페이지(회원가입, 게시판 등), 배송 요청</TH3>
          <TH1 sx={{mt:'20px', mb:'24px'}}>개인정보의 수집 및 이용 목적</TH1>
          <TH3 sx={{mb:'6px'}}>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</TH3>
          <TH4 sx={{mb:'6px'}}>1. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산</TH4>
          <TH4 sx={{mb:'6px'}}>2. 콘텐츠 제공, 구매 및 요금 결제, 물품 배송 또는 청구지 등 발송</TH4>
          <TH4 sx={{mb:'6px'}}>3. 회원 관리</TH4>
          <TH4 sx={{mb:'6px'}}>4. 회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령확인, 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의 여부 확인, 불만처리 등 민원 처리, 고지사항 전달</TH4>
          <TH4 sx={{mb:'6px'}}>5. 마케팅 및 광고에 활용</TH4>
          <TH4 sx={{mb:'6px'}}>6. 이벤트 등 광고성 정보 전달, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계</TH4>
          <TH4 sx={{mb:'6px'}}>단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강 상태 및 성생활 등)는 수집하지 않습니다.</TH4>
          <TH1 sx={{mt:'20px', mb:'24px'}}>개인정보의 보유 및 이용 기간</TH1>
          <TH3 sx={{mb:'6px'}}>귀하의 개인정보는 다음과 같이 개인정보의 수집 목적 또는 제공받은 목적이 달성되면 파기됩니다.</TH3>

          <TH4 sx={{mb:'6px'}}>1. 회원가입 정보의 경우, 회원가입을 탈퇴하거나 회원에서 제명된 때</TH4>
          <TH4 sx={{mb:'6px'}}>2. 대금 지급 정보의 경우, 대금의 완제일 또는 채권 소멸시효기간의 만료된 때</TH4>

          <TH1 sx={{mt:'20px', mb:'24px'}}>개인정보의 파기 절차 및 방법</TH1>

          <TH3 sx={{mb:'6px'}}>회사는 원칙적으로 개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.</TH3>

          <TH4 sx={{mb:'6px'}}>1. 파기 절차: 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용 기간 참조) 일정 기간 저장된 후 파기됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.</TH4>
          <TH4 sx={{mb:'6px'}}>2. 파기 방법: 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</TH4>
          <TH1 sx={{mt:'20px', mb:'24px'}}>개인정보 제공</TH1>

          <TH3 sx={{mb:'6px'}}>회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</TH3>
          <TH4 sx={{mb:'6px'}}>1. 이용자들이 사전에 동의한 경우</TH4>
          <TH4 sx={{mb:'6px'}}>2. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</TH4>

          <TH1 sx={{mt:'20px', mb:'24px'}}>이용자 및 법정대리인의 권리와 그 행사 방법</TH1>

          <TH4 sx={{mb:'6px'}}>1. 이용자는 언제든지 등록된 자신의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다.</TH4>

          <TH4 sx={{mb:'6px'}}>2. 이용자들의 개인정보 조회, 수정을 위해서는 ‘개인정보 변경’(또는 ‘회원정보 수정’ 등)을 가입 해지(동의 철회)를 위해서는 “회원 탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.</TH4>
          <TH4 sx={{mb:'6px'}}>혹은 개인정보관리 책임자에게 서면, 전화 또는 이메일로 연락하시면 바로 조치하겠습니다.</TH4>
          <TH3 sx={{mb:'6px'}}>귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.</TH3>
          <TH3 sx={{mb:'6px'}}>회사는 이용자의 요청으로 해지 또는 삭제된 개인정보는 “회사가 수집하는 개인정보의 보유 및 이용 기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</TH3>
          <TH1 sx={{mt:'20px', mb:'24px'}}>개인정보보호를 위한 기술적 대책</TH1>
          <TH3 sx={{mb:'6px'}}>회사는 귀하의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.</TH3>
          <TH3 sx={{mb:'6px'}}>귀하의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능(Lock)을 사용하여 중요한 데이터는 별도의 보안 기능을 통해 보호되고 있습니다.</TH3>
          <TH3 sx={{mb:'6px'}}>회사는 백신 프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.</TH3>
          <TH3 sx={{mb:'6px'}}>회사는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL 또는 SET)를 채택하고 있습니다.</TH3>
          <TH3 sx={{mb:'6px'}}>해킹 등에 의해 귀하의 개인정보가 유출되는 것을 방지하기 위해, 외부로부터의 침입을 차단하는 장치를 이용하고 있으며, 각 서버마다 침입탐지시스템을 설치하여 24시간 침입을 감시하고 있습니다.</TH3>
          <TH1 sx={{mt:'20px', mb:'24px'}}>개인정보 자동수집 장치의 설치ㆍ운영 및 그 거부에 관한 사항</TH1>
          <TH3 sx={{mb:'6px'}}>회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 oo의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.</TH3>
          <TH3 sx={{mb:'6px'}}>회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.</TH3>
          <TH3 sx={{mb:'6px'}}>회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공</TH3>
          <TH3 sx={{mb:'6px'}}>귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</TH3>
          <TH3 sx={{mb:'6px'}}>&lt;쿠키 설정 거부 방법&gt;</TH3>
          <TH4 sx={{mb:'6px'}}>예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.</TH4>
          <TH4 sx={{mb:'6px'}}>설정방법 예(인터넷 익스플로어의 경우)</TH4>
          <TH4 sx={{mb:'6px'}}>- 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보</TH4>
          <TH4 sx={{mb:'6px'}}>단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.</TH4>
          <TH1 sx={{mt:'20px', mb:'24px'}}>개인정보에 관한 민원 서비스</TH1>
          <TH3 sx={{mb:'6px'}}>회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리 책임자를 지정하고 있습니다.</TH3>
          <TH4 sx={{mb:'6px'}}>개인정보관리 책임자 성명 :</TH4>
          <TH4 sx={{mb:'6px'}}>전화번호 :</TH4>
          <TH4 sx={{mb:'6px'}}>이메일 :</TH4>
          <TH3 sx={{mb:'6px'}}>귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리 책임자 혹은 담당 부서로 신고하실 수 있습니다. 회사는 이용자들의 신고 사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</TH3>
          <TH3 sx={{mb:'6px'}}>기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</TH3>
          <TH4 sx={{mb:'6px'}}>1. 개인 분쟁조정위원회 (www.1336.or.kr/1336)</TH4>
          <TH4 sx={{mb:'6px'}}>2. 정보보호 마크 인증위원회 (www.eprivacy.or.kr/02-580-0533~4)</TH4>
          <TH4 sx={{mb:'6px'}}>3. 대검찰청 인터넷 범죄 수사센터 (http://icic.sppo.go.kr/02-3480-3600)</TH4>
          <TH4 sx={{mb:'6px'}}>4. 경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)</TH4>

        </Box>
      </Container>
    </React.Fragment>
  );
};
