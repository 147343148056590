import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Container,
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Paper,
  Stack,
  Typography
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import MyReservationView from '../../../components/app/meeting/MyReservationView';
import MeetingRoomView from '../../../components/app/meeting/MeetingRoomView';
import MeetingTableView from '../../../components/app/meeting/MeetingTableView';

import axios from "axios";

const SampleReservation = [
  {
    reserve : true,
    type : "Room A1",
    time : "2024년 3월 20일 16시 - 17시",
    people : 2
  },
  {
    reserve : false,
    type : "Room A2",
    time : "2024년 3월 20일 17시 - 18시",
    people : 2
  }
];

const MeetingRoomList = [
  {
    src : '/assets/image/meeting-1.jpg',
    name : '대형 회의실 1',
    max : 16,
    description : '많은 사람을 수용 가능한 대형 회의실이며, 프로젝터 및 오디오 시스템을 갖추고 있습니다.'
  },
  {
    src : '/assets/image/meeting-1.jpg',
    name : '소형 A1',
    max : 4,
    description : '소규모 미팅을 하기에 적합한 회의실입니다'
  },
  {
    src : '/assets/image/meeting-1.jpg',
    name : '소형 A2',
    max : 4,
    description : '소규모 미팅을 하기에 적합한 회의실입니다'
  }
]

function MeetingManagePage(props){

  const [myPoint, setMyPoint] = useState({
    total : 0,
    used : 0,
    remain : 0
  });

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative',  boxSizing:'border-box' }}>
      {/*<Box sx={{width:'100%', position:'fixed', top:'0px', left:'0px'}}>
        <Toolbar />
        <Box sx={{width:'100%', backgroundColor:teal[700], p:'6px 32px'}}>
          <Typography variant="h6" componant="p" sx={{textAlign:"left", color:'#ffffff', fontSize:'14px'}}>
            <Typography
              onClick={handlePreviousVersion}
              variant="p"
              componant="span"
              sx={{textAlign:"left", color:'#ffffff', fontSize:'14px', mr:'12px', cursor:'pointer'}}>
              기존 버전 으로 돌아가기
            </Typography>
            ( 안정화 전까지 기존 버전이 운영됩니다 )
          </Typography>
        </Box>
      </Box>*/}
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Typography variant="h6" element="p" sx={{
          textAlign:"left",
          fontFamily: "NotoSansKR",
          fontWeight:700,
          position:'relative',
          mb:'24px'}}>
          미팅룸 예약
          <Button
            variant="outlined"
            sx={{
              position:'absolute',
              fontFamily: "NotoSansKR",
              textSize:'18px',
              fontWeight:700,
              right:'0px', top:"0px"}}>
           예약하기
          </Button>
        </Typography>
        <Box sx={{position:'relative', width:'100%'}}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={2}
            alignItems="flex-start">
            <Grid
              item
              md={4}>
              <Paper
                elevation={4}
                sx={{
                  borderRadius:'16px',
                  width:'100%',
                  p:2}}>
                <Typography element="p" sx={{
                  textAlign:"left",
                  fontFamily: "NotoSansKR",
                  textSize:'20px',
                  fontWeight:700,
                  mb:'12px'}}>
                  포인트
                </Typography>
                <Box
                  sx={{
                    height:'2px',
                    width:'32px',
                    borderRadius:'2px',
                    mb:'12px',
                    backgroundColor:blue[500]
                  }}/>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        월 지급 포인트
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        {myPoint.total} pt
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        금월 사용 포인트
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        {myPoint.used} pt
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        남은 포인트
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>
                        {myPoint.remain} pt
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              </Paper>
            </Grid>
            <Grid
              item
              md={8}>
              <Paper
                elevation={4}
                sx={{
                  borderRadius:'16px',
                  width:'100%',
                  p:2}}>
                <Typography element="p" sx={{
                  textAlign:"left",
                  fontFamily: "NotoSansKR",
                  textSize:'18px',
                  fontWeight:700,
                  mb:'16px'}}>
                  나의 예약
                </Typography>
                <Stack
                  sx={{width:'100%'}}
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                  {SampleReservation.map((data, index) => (
                    <MyReservationView
                      {...data} />
                  ))}
                </Stack>
              </Paper>
            </Grid>
            <Grid
              item
              md={12}>
              <Box sx={{width:'100%', height:'16px'}} />
              <Typography variant="h6" element="p" sx={{
                textAlign:"left",
                fontFamily: "NotoSansKR",
                fontWeight:700,
                position:'relative',
                mb:'24px'}}>
                미팅룸 목록
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}>
                {MeetingRoomList.map((data, index) => (
                  <Grid item md={3}>
                    <MeetingRoomView
                      {...data} />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid
              item
              md={12}>
              <Box sx={{width:'100%', height:'16px'}} />
              <Typography variant="h6" element="p" sx={{
                textAlign:"left",
                fontFamily: "NotoSansKR",
                fontWeight:700,
                position:'relative',
                mb:'24px'}}>
                미팅룸 공지사항
              </Typography>
              <MeetingTableView />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default MeetingManagePage;
