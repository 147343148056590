import React, {useEffect} from 'react';

import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import {
  Container,
  Paper,
  Stack,
  Grid,
  TextField,
  FormGroup,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormHelperText,
  Checkbox,
  IconButton,
  Link,
  Button
} from '@mui/material';
import { IMaskInput } from 'react-imask';

// color
import { teal } from '@mui/material/colors';

import axios from "axios";


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function AccountPage(props){

  const handlePreviousVersion = () => {
      props.__versionChange("V1");
  }

  const navigate = useNavigate();

  const handleSignin = (event) => {
      navigate("/auth/signin");
  };

  const [values, setValues] = React.useState({
    id: '',
    password: '',
    name: '',
    number: '',
    usage : false,
    privacy: false,
    showPassword: false,
  });

  const [viewErrors, setViewErrors] = React.useState({
    id: false,
    name: false,
    number: false,
    password: false,
    usage: false,
    privacy : false
  });

  const [viewActive, setViewActive] = React.useState(true);

  const handleChange = (prop) => (event) => {
    handleViewErrors("id", false);
    handleViewErrors("name", false);
    handleViewErrors("number", false);
    handleViewErrors("password", false);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleViewErrors = (prop, error) => {
    setViewErrors({ ...viewErrors, [prop]: error });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter' && e.target.value) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setViewActive(false);
    let valid = true;
    if(values.id.length === 0){
      handleViewErrors("id", true);
      valid = false;
    }
    if(values.password.length === 0){
      handleViewErrors("password", true);
      valid = false;
    }
    if(!valid){
      setViewActive(true);
      return;
    }
    props.setToken("1111");
    //requestLogin();
  };


  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative' }}>
      <Container
        sx={{
          height:"100vh",
          maxWidth:"100vw !important",
          backgroundColor:"rgba(0,0,0,.5)"
        }}>
        <img
          style={{
            position:'fixed',
            top:0,
            left:0,
            right:0,
            bottom:0,
            zIndex:-1,
            width:'100%',
            height:'100%'}}
          src={`/assets/image/auth-background.jpg`}
          srcSet={`/assets/image/auth-background.jpg`}
          loading="lazy"/>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            height:"100vh"
          }}>
          <Paper
            elevation={2}
            sx={{
              width:'100%',
              maxWidth:'840px',
              padding:'36px 24px',
              backgroundColor:"#fff",
              borderRadius:'16px'
            }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}>
              <Grid
                item
                md={6}>
                <Stack
                  spacing={2}
                  justifyContent="center"
                  alignItems="center">
                  <img
                    style={{
                      marginBottom:'24px',
                      height:'72px'}}
                    src={`/assets/icons/gigto_space_black_logo.png`}
                    srcSet={`/assets/icons/gigto_space_black_logo.png`}
                    loading="lazy"/>
                  <Typography
                    sx={{
                      fontFamily:'NotoSansKR',
                      fontWeight:900,
                      color:'#222222',
                      fontSize:'24px',
                      padding:'0px 16px'
                    }}>
                    저렴하고 효율적인 소호오피스
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily:'NotoSansKR',
                      fontWeight:900,
                      color:'#555555',
                      fontSize:'16px',
                      padding:'0px 16px'
                    }}>
                    지금 긱토 스페이스의 회원이 되어 성공적인 기업을 만들어 보세요
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                md={6}>
                <Stack
                  spacing={2}
                  justifyContent="center"
                  alignItems="center">
                  <TextField
                    id="outlined-password-input"
                    label="이메일"
                    error={viewErrors.id}
                    sx={{
                      width:"100%",
                      maxWidth:"250px",
                      borderRadius:'24px',
                    }}
                    disabled={!viewActive}
                    helperText={viewErrors.id ? "이메일을 입력해주세요" : ""}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange('id')}
                    InputProps={{ sx: { borderRadius: '24px', height:'48px' } }}
                    autoComplete="off"/>
                  <TextField
                    id="outlined-name-input"
                    label="이름"
                    error={viewErrors.name}
                    sx={{
                      width:"100%",
                      maxWidth:"250px",
                      borderRadius:'24px',
                    }}
                    disabled={!viewActive}
                    helperText={viewErrors.name ? "이름을 입력해주세요" : ""}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange('name')}
                    InputProps={{ sx: { borderRadius: '24px', height:'48px' } }}
                    autoComplete="off"/>
                  <TextField
                    id="outlined-number-input"
                    label="전화번호"
                    error={viewErrors.number}
                    sx={{
                      width:"100%",
                      maxWidth:"250px",
                      borderRadius:'24px',
                    }}
                    disabled={!viewActive}
                    helperText={viewErrors.number ? "전화번호을 입력해주세요" : ""}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange('number')}
                    InputProps={{ sx: { borderRadius: '24px', height:'48px' } }}
                    autoComplete="off"/>
                  <FormControl sx={{
                      width:"100%",
                      maxWidth:"250px"
                    }} variant="outlined"
                    error={viewErrors.password}>
                    <InputLabel htmlFor="outlined-adornment-password">패스워드</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? 'text' : 'password'}
                      value={values.password}
                      disabled={!viewActive}
                      aria-describedby="component-error-text"
                      onKeyDown={handleKeyDown}
                      onChange={handleChange('password')}
                      sx={{borderRadius:'24px', height:'48px'}}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                    {viewErrors.password && (<FormHelperText id="component-error-text">패스워드를 입력해주세요</FormHelperText>)}
                  </FormControl>
                  <Box sx={{
                    width:'100%',
                    mt:'8px',
                    maxWidth:'250px'}}>
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          fontFamily:'NotoSansKR',
                          '& .MuiFormControlLabel-label': {
                            fontSize: '12px',
                            fontFamily:'NotoSansKR' }
                        }}
                        required control={<Checkbox disabled={!viewActive}/>} label="이용약관에 동의합니다" />
                      <FormControlLabel
                        sx={{
                          fontFamily:'NotoSansKR',
                          '& .MuiFormControlLabel-label': {
                            fontSize: '12px',
                            fontFamily:'NotoSansKR' }
                        }}
                        required control={<Checkbox disabled={!viewActive}/>} label="개인정보수집 및 이용에 동의합니다" />
                    </FormGroup>
                  </Box>
                  <Button
                    disableElevation
                    sx={{
                      width:'100%',
                      maxWidth:'250px',
                      height:'48px',
                      borderRadius:'24px'}}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!viewActive}
                    endIcon={<ArrowRightAltIcon color="white"/>}>
                    회원가입하기
                  </Button>
                  <Button
                    size="small"
                    onClick={handleSignin}
                    sx={{fontSize:"12px", color:"#888"}}
                    variant="text">이미 계정이 있습니다</Button>
                </Stack>
              </Grid>
              <Grid
                sx={{mt:'12px'}}
                md={12}>
                <Stack
                  sx={{width:'100%'}}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}>
                  <Link href="/common/usage" sx={{textDecoration:'none'}}>
                    <Button
                      size="small"
                      sx={{fontSize:"12px", color:"#888"}}
                      variant="text">이용약관</Button>
                  </Link>
                  <Link href="/common/privacy" sx={{textDecoration:'none'}}>
                    <Button
                      size="small"
                      sx={{fontSize:"12px", color:"#888"}}
                      variant="text">개인정보수집 및 이용</Button>
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Stack>
      </Container>
    </Box>
  );
}

export default AccountPage;
