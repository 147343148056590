import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import { teal } from '@mui/material/colors';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import Face2OutlinedIcon from '@mui/icons-material/Face2Outlined';

import {
  Button,
  IconButton,
  Grid
} from '@mui/material';

import EffectScroll from '../util/EffectScroll';
import EventBanner from '../banner/EventBanner';

function Header(props){

  const navigate = useNavigate();
  const headerButtonOnClick = (type) => (event) => {
    switch(type){
      case "HOME" : navigate("/"); break;
      case "ALLIANCE" : navigate("/alliance"); break;
      case "PRICE" : navigate("/price"); break;
      case "HOWTO" :  navigate("/howto"); break;
      case "FAQ" :  navigate("/faq"); break;
      case "SIGNIN" : navigate("/auth/signin"); break;
      default : break;
    }
  };

  return (
    <EffectScroll {...props}>
      <AppBar sx={{backgroundColor:'black', height:'auto'}}>
        <EventBanner />
        <Toolbar sx={{position:'relative', height:'100%'}}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{height:'100%', width:'100%'}}
            spacing={2}>
            <Typography variant="h6" component="div">
              <Link href="/">
                <img src="/assets/icons/gigto_space_white_logo.png" style={{height:'36px', position:'relative'}}/>
              </Link>
            </Typography>
            <Box sx={{flexGrow:1}}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}>
                <Button variant="text"
                  onClick={headerButtonOnClick("HOME")}
                  sx={{color:'#ffffff', fontFamily: "NotoSansKR"}}>공간 소개</Button>
                <Button variant="text"
                  onClick={headerButtonOnClick("PRICE")}
                  sx={{color:'#ffffff', fontFamily: "NotoSansKR"}}>비용 안내</Button>
                <Button variant="text"
                  onClick={headerButtonOnClick("ALLIANCE")}
                  sx={{color:'#ffffff', fontFamily: "NotoSansKR"}}>제휴 서비스</Button>
                <Button variant="text"
                  onClick={headerButtonOnClick("HOWTO")}
                  sx={{color:'#ffffff', fontFamily: "NotoSansKR"}}>이용방법</Button>
                <Button variant="text"
                  onClick={headerButtonOnClick("FAQ")}
                  sx={{color:'#ffffff', fontFamily: "NotoSansKR"}}>자주묻는질문</Button>
              </Stack>
            </Box>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}>
              <IconButton sx={{color:'#ffffff'}}>
                <SearchOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={headerButtonOnClick("SIGNIN")}
                sx={{color:'#ffffff'}}>
                <Face2OutlinedIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
    </EffectScroll>
  );
}

export default Header;
