import React, {useRef, useEffect, useState} from 'react';

import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { pink } from '@mui/material/colors';

import { SnackbarProvider, useSnackbar } from 'notistack';

import css_main from '../../css/main.module.css';

function NonSignedLayoutInner(props) {
  const { window } = props;
  const navigate = useNavigate();

  const [appBarHeight, setAppBarHeight] = useState(0);

  const appbarTop = useRef(null);

  const handleBackAction = (event) => {
    navigate(-1);
  };

  useEffect(() => {
    setAppBarHeight(appbarTop.current.clientHeight);
  });

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', height:"100vh" }}>
      <AppBar component="nav"
        ref={appbarTop}
        elevation={0}
        sx={{backgroundColor : "#ffffff", borderBottom:'1px solid #dddddd'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="moving back page"
            edge="start"
            onClick={handleBackAction}
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon
               sx={{ color: '#000000' }}/>
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color:'#000000', textAlign:'left', display: { xs: 'none', sm: 'block' } }}>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ width: "100%", overflow:"hidden" }}>
        <Toolbar />
        <Box component="div" sx={{position:'relative',
          width:"100%", p:'24px',
          height:`calc(100vh - ${appBarHeight}px)`,
          overflow:'scroll'}}>
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}

NonSignedLayoutInner.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function NonSignedSubLayout(props) {
  return (
    <NonSignedLayoutInner {...props}>
      {props.children}
    </NonSignedLayoutInner>
  );
}
