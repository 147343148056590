import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function HowtoAccordion(props) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="howto-accordion"
        id={`howto-accordion-${props.id}`}
      >
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontWeight:700,
            color:'#000000'
          }}>{props.q}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontWeight:500,
            whiteSpace: "pre-wrap"
          }}>
          {props.a}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
