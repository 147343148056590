import React, { useRef, useState } from 'react';

import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  Stack,
  Divider
} from '@mui/material';

function Footer(){
  return (
    <Box sx={{backgroundColor:'black', pt:'48px', pb:'48px'}}>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}>
              <Link href="/">
                <img src="/assets/icons/gigto_space_white_logo.png" style={{height:'36px', position:'relative', filter: 'brightness(0.8)'}}/>
              </Link>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}>
              <Typography sx={{color:'#cccccc', textAlign:'left', fontSize:'18px', pb:'12px', fontWeight:'900'}}>
                Gigto space
              </Typography>
              <Link href="/" sx={{textDecoration:'none'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  공간 소개
                </Typography>
              </Link>
              <Link href="/" sx={{textDecoration:'none'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  사용 요금
                </Typography>
              </Link>
              <Link href="/" sx={{textDecoration:'none'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  제휴 상품
                </Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}>
              <Typography sx={{color:'#cccccc', textAlign:'left', fontSize:'18px', pb:'12px', fontWeight:'900'}}>
                서비스
              </Typography>
              <Link href="/" sx={{textDecoration:'none'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  미팅룸 예약
                </Typography>
              </Link>
              <Link href="/" sx={{textDecoration:'none'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  제휴 관련
                </Typography>
              </Link>
              <Link href="/" sx={{textDecoration:'none'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  입주사 전용
                </Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item md={12} sx={{mt:'24px'}}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem sx={{borderColor:'#777777'}}/>}>
                <Link href="/" sx={{textDecoration:'none'}}>
                  <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                    공지사항
                  </Typography>
                </Link>
                <Link href="/common/usage" sx={{textDecoration:'none'}}>
                  <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                    이용약관
                  </Typography>
                </Link>
                <Link href="/common/privacy" sx={{textDecoration:'none'}}>
                  <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                    개인정보처리방침
                  </Typography>
                </Link>
              </Stack>
          </Grid>
          <Grid item container md={12}>
            <Grid item md={2}>
              <Typography sx={{color:'#aaaaaa', textAlign:'left', fontSize:'12px'}}>
                ⓒ Gigto Space 2023
              </Typography>
            </Grid>
            <Grid item md>
            </Grid>
            <Grid item md={2}>
              <Typography sx={{color:'#aaaaaa', textAlign:'right', fontSize:'14px'}}>
                Powered by Gigto
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer;
