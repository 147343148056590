import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';

import {
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Paper
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';


import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import TabPanel from '../../components/price/TabPanel';
import PriceBox from '../../components/price/PriceBox';

import FirstRentPanel from './panels/FirstRentPanel';
import SecondRentPanel from './panels/SecondRentPanel';
import ThirdRentPanel from './panels/ThirdRentPanel';

import axios from "axios";

function PricePage(props) {

  const [tab, setTab] = React.useState(0);
  const [hoverElement, setHoverElement] = React.useState(-1);

  const handlePopoverOpen = (element) => (event) => {
    setHoverElement(element)
  };

  const handlePopoverClose = () => {
    setHoverElement(-1);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Header {...props} />
      <Box sx={{height:'auto', backgroundColor:'black'}}>
        <Box sx={{width:'100%', height:'60px'}} />
        <Toolbar sx={{position:'relative', height:'100%'}}>
        </Toolbar>
      </Box>
      <Container>
        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{minHeight:'100vh'}}
            alignItems="center">
            <Grid item md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:700,
                  fontSize:'28px',
                  textAlign:'center',
                  mb:'24px',
                  color:lightBlue[600]
                }}>
                다양한 형태의 오피스를 저렴하게 이용해보세요
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:700,
                  fontSize:'18px',
                  textAlign:'center',
                  color:blueGrey[800]
                }}>
                1~2인 오피스를 비롯하여, 10인 오피스까지 다양한 옵션이 준비되어 있습니다.
                오픈된 공간의 개인 좌석도 마련되어 있으니 공간에 대한 고민이 있는 스타트업 및 중소기업이라면 요금을 확인해보세요
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Paper
        elevation={0}
        sx={{
          backgroundColor:blueGrey[50],
          width:'100%',
        }}>
        <Container>
          <Box sx={{ my: 2 }}>
            <Grid container spacing={2}
              direction="row"
              justifyContent="center"
              sx={{minHeight:'100vh'}}
              alignItems="center">
              <Grid item md={12}>

                <Tabs value={tab}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  centered>
                  <Tab label="오픈 좌석" />
                  <Tab label="1~2인실" />
                  <Tab label="+8인실" />
                </Tabs>
              </Grid>
              <Grid item md={12}>
                <FirstRentPanel
                  tab={tab}
                  index={0}
                  hoverElement={hoverElement}
                  handlePopoverOpen={handlePopoverOpen}
                  handlePopoverClose={handlePopoverClose}/>
                <SecondRentPanel
                  tab={tab}
                  index={1}
                  hoverElement={hoverElement}
                  handlePopoverOpen={handlePopoverOpen}
                  handlePopoverClose={handlePopoverClose}/>
                <ThirdRentPanel
                  tab={tab}
                  index={2}
                  hoverElement={hoverElement}
                  handlePopoverOpen={handlePopoverOpen}
                  handlePopoverClose={handlePopoverClose}/>
                <TabPanel value={tab} index={2}>
                </TabPanel>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Paper>
      <Container>
        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{minHeight:'100vh'}}
            alignItems="center">
          <Grid item md={6}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                textAlign:'left',
                color:lightBlue[600]
              }}>
              주차 및 회의는 신경쓰지 마세요
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                fontSize:'24px',
                textAlign:'left',
                mb:'24px',
                color:blueGrey[800]
              }}>
              넉넉한 회의 공간 및 언제든 이용 가능한 주차시설
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:500,
                fontSize:'18px',
                textAlign:'left',
                color:blueGrey[800]
              }}>
              Gigto Space에는 1개의 대형 회의실 및 3개의 소형 회의실로 사용 인원 대비 넉넉한 회의실을 보유하고 있으며,
              이외에도 충분한 주차공간을 통해, 24시간 언제든 주차가 가능합니다. 더 이상 부가적인 시설 부족으로 마음 고생 하지 마세요
            </Typography>
          </Grid>
          <Grid item md={6}>

          </Grid>
          </Grid>
        </Box>

        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{minHeight:'100vh'}}
            alignItems="center">
          <Grid item md={6}>

          </Grid>
          <Grid item md={6}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                textAlign:'left',
                color:lightBlue[600]
              }}>
              제조업 및 유통 등에 특화된 오피스
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                fontSize:'24px',
                textAlign:'left',
                mb:'24px',
                color:blueGrey[800]
              }}>
              문래동에 위치하여, 각종 유통 상가와 접근성이 뛰어난 오피스
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:500,
                fontSize:'18px',
                textAlign:'left',
                color:blueGrey[800]
              }}>
              도보로 도림천역(2호선) 10분, 문래역(2호선) 15분의 접근성을 가지고 있으며, 문래 창작촌 및 구로 디지털 단지,
              가산 디지털 단지와 가까운 서울 서부 권역으로 제조업 및 유통 사업을 중점으로 하는 스타트업에게 유리한 위치를 가지고 있습니다.
            </Typography>
          </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

export default PricePage;
