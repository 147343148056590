import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Container,
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Paper,
  Stack,
  Typography
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import OfficeView from '../../../components/app/office/OfficeView';

import axios from "axios";

const OfficeList = [
  {
    src : '/assets/image/meeting-1.jpg',
    name : '대형 회의실 1',
    max : 16,
    description : '많은 사람을 수용 가능한 대형 회의실이며, 프로젝터 및 오디오 시스템을 갖추고 있습니다.'
  },
  {
    src : '/assets/image/meeting-1.jpg',
    name : '소형 A1',
    max : 4,
    description : '소규모 미팅을 하기에 적합한 회의실입니다'
  },
  {
    src : '/assets/image/meeting-1.jpg',
    name : '소형 A2',
    max : 4,
    description : '소규모 미팅을 하기에 적합한 회의실입니다'
  }
]

function OfficeManagePage(props){

  const [myPoint, setMyPoint] = useState({
    total : 0,
    used : 0,
    remain : 0
  });

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative',  boxSizing:'border-box' }}>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Typography variant="h6" element="p" sx={{
          textAlign:"left",
          fontFamily: "NotoSansKR",
          fontWeight:700,
          position:'relative',
          mb:'24px'}}>
          오피스 관리
          <Button
            variant="outlined"
            sx={{
              position:'absolute',
              fontFamily: "NotoSansKR",
              textSize:'18px',
              fontWeight:700,
              right:'0px', top:"0px"}}>
           추가 계약하기
          </Button>
        </Typography>
        <Box sx={{position:'relative', width:'100%'}}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={2}
            alignItems="flex-start">
            <Grid
              item
              md={12}>
              <Paper
                elevation={4}
                sx={{
                  borderRadius:'16px',
                  width:'100%',
                  p:2}}>
                <Typography element="p" sx={{
                  textAlign:"left",
                  fontFamily: "NotoSansKR",
                  textSize:'20px',
                  fontWeight:700,
                  mb:'12px'}}>
                  나의 오피스
                </Typography>
                <Box
                  sx={{
                    height:'2px',
                    width:'32px',
                    borderRadius:'2px',
                    mb:'12px',
                    backgroundColor:blue[500]
                  }}/>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        오피스 타입
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>

                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        계약 기간
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>

                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={6}>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        fontWeight:500}}>
                        받고 있는 혜택
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography element="p" sx={{
                        textAlign:"left",
                        fontFamily: "NotoSansKR",
                        textSize:'14px',
                        textAlign:'right',
                        fontWeight:500}}>

                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              </Paper>
            </Grid>
            <Grid
              item
              md={12}>
              <Box sx={{width:'100%', height:'16px'}} />
              <Typography variant="h6" element="p" sx={{
                textAlign:"left",
                fontFamily: "NotoSansKR",
                fontWeight:700,
                position:'relative',
                mb:'24px'}}>
                추가 오피스 목록
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}>
                {OfficeList.map((data, index) => (
                  <Grid item md={3}>
                    <OfficeView
                      {...data} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default OfficeManagePage;
