import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';


import {
  MainPage,
  PricePage,
  AlliancePage,
  HowtoPage,
  FAQPage,
  LoginPage,
  FindPasswordPage,
  ChangePasswordPage,
  AccountPage,

  QueueLayout,
  UsagePage,
  PrivacyPage,
  NoticePage,
  NonSignedSubLayout
} from '../containers'

export default function NonSignedRoute(props){
  return (
    <Routes>
      <Route path='/' element={<MainPage setToken={props.setToken} />} />
      <Route path='/price' element={<PricePage setToken={props.setToken} />} />
      <Route path='/alliance' element={<AlliancePage setToken={props.setToken} />} />
      <Route path='/howto' element={<HowtoPage setToken={props.setToken} />} />
      <Route path='/faq' element={<FAQPage setToken={props.setToken} />} />
      <Route path='/faq' element={<FAQPage setToken={props.setToken} />} />
      <Route path='*' element={<Navigate to="/" />} />

      <Route path='/auth/signin' element={<LoginPage setToken={props.setToken} />} />
      <Route path='/auth/change' element={<ChangePasswordPage setToken={props.setToken} />} />
      <Route path='/auth/findAccount' element={<FindPasswordPage setToken={props.setToken} />} />
      <Route path='/auth/signup' element={<AccountPage setToken={props.setToken} />} />

      <Route path='/common/usage' element={
        <QueueLayout>
          <NonSignedSubLayout title={"이용약관"}>
            <UsagePage setToken={props.setToken} />
          </NonSignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/common/privacy' element={
        <QueueLayout>
          <NonSignedSubLayout title={"개인정보처리방침"}>
            <PrivacyPage setToken={props.setToken} />
          </NonSignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/common/notice' element={
        <QueueLayout>
          <NonSignedSubLayout title={"공지사항"}>
            <PrivacyPage setToken={props.setToken} />
          </NonSignedSubLayout>
        </QueueLayout>
        } />

    </Routes>
  );
}
