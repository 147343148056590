import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

function EffectScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  const trigger_elevation = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  const _children = React.cloneElement(children, {
    elevation: trigger_elevation ? 4 : 0,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {_children}
    </Slide>
  );
}

EffectScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default EffectScroll;
