import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import {
  Paper
} from '@mui/material';

import NoticeTableView from '../../components/app/dashboard/NoticeTableView';
//import DOReviewController from '../../apis/dailyorder/DOReviewController';
// color
import { teal } from '@mui/material/colors';

import axios from "axios";

function DashboardPage(props){

  const handlePreviousVersion = () => {
      props.__versionChange("V1");
  }

  const [files, setFiles] = useState([]);



  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative',  boxSizing:'border-box' }}>
      {/*<Box sx={{width:'100%', position:'fixed', top:'0px', left:'0px'}}>
        <Toolbar />
        <Box sx={{width:'100%', backgroundColor:teal[700], p:'6px 32px'}}>
          <Typography variant="h6" componant="p" sx={{textAlign:"left", color:'#ffffff', fontSize:'14px'}}>
            <Typography
              onClick={handlePreviousVersion}
              variant="p"
              componant="span"
              sx={{textAlign:"left", color:'#ffffff', fontSize:'14px', mr:'12px', cursor:'pointer'}}>
              기존 버전 으로 돌아가기
            </Typography>
            ( 안정화 전까지 기존 버전이 운영됩니다 )
          </Typography>
        </Box>
      </Box>*/}
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Typography variant="h6" element="p" sx={{
          textAlign:"left",
          fontFamily: "NotoSansKR",
          fontWeight:700,
          position:'relative',
          whiteSpace: "pre-line",
          mb:'24px'}}>
          안녕하세요
          <br/> Guest님
          <br/> 오늘은 스트레칭으로 시작해볼까요?
        </Typography>
        <Box sx={{position:'relative', width:'100%'}}>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-start">
            <Grid
              item
              sx={{height:'150px'}}
              md={3}>
              <Paper
                sx={{width:'100%',
                padding:'16px',
                height:'100%',
                position:'relative',
                borderRadius:'16px'}}
                elevation={4}>
                <img src="/assets/icons/quiz.png"
                  style={{
                    width:'72px',
                    height:'72px',
                    position:'absolute',
                    bottom:'12px',
                    right:'12px'
                  }}/>
                  <Box sx={{
                    position:'absolute',
                    borderRadius:'16px',
                    width:'100%',
                    height:'100%',
                    top:'0px',
                    left:'0px',
                    bottom:'0px',
                    right:'0px',
                    background:'linear-gradient(90deg, rgba(255,255,255,1) , rgba(255,255,255,0))'
                  }} />
                <Typography variant="h6" element="p" sx={{
                  textAlign:"left",
                  fontFamily: "NotoSansKR",
                  fontWeight:700,
                  fontSize:'16px',
                  position:'relative',
                  whiteSpace: "pre-line",
                  mb:'18px'}}>
                  궁금한 점이 있으신가요?
                  <br/>1:1 문의하기로 문의해 주세요
                </Typography>
                <Typography variant="h6" element="p" sx={{
                  textAlign:"left",
                  fontFamily: "NotoSansKR",
                  fontWeight:300,
                  fontSize:'13px',
                  position:'relative',
                  color:'#666666',
                  whiteSpace: "pre-line"}}>
                  최대한 빠르게 답변을 드리도록 하겠습니다
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              sx={{height:'150px'}}
              md={3}>
              <Paper
                sx={{width:'100%',
                padding:'16px',
                height:'100%',
                position:'relative',
                borderRadius:'16px'}}
                elevation={4}>
                <img src="/assets/icons/travel-guide.png"
                  style={{
                    width:'72px',
                    height:'72px',
                    position:'absolute',
                    bottom:'12px',
                    right:'12px'
                  }}/>
                  <Box sx={{
                    position:'absolute',
                    borderRadius:'16px',
                    width:'100%',
                    height:'100%',
                    top:'0px',
                    left:'0px',
                    bottom:'0px',
                    right:'0px',
                    background:'linear-gradient(90deg, rgba(255,255,255,1) , rgba(255,255,255,0))'
                  }} />
                <Typography variant="h6" element="p" sx={{
                  textAlign:"left",
                  fontFamily: "NotoSansKR",
                  fontWeight:700,
                  position:'relative',
                  fontSize:'16px',
                  whiteSpace: "pre-line",
                  mb:'18px'}}>
                  Gigto space
                  <br/>이용 안내
                </Typography>
                <Typography variant="h6" element="p" sx={{
                  textAlign:"left",
                  fontFamily: "NotoSansKR",
                  fontWeight:300,
                  fontSize:'13px',
                  position:'relative',
                  color:'#666666',
                  whiteSpace: "pre-line"}}>
                  오피스를 이용하시는 내용을 모았습니다
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              sx={{height:'150px'}}
              md={3}>
              <Paper
                sx={{width:'100%',
                padding:'16px',
                height:'100%',
                position:'relative',
                borderRadius:'16px'}}
                elevation={4}>
                <img src="/assets/icons/question.png"
                  style={{
                    width:'72px',
                    height:'72px',
                    position:'absolute',
                    bottom:'12px',
                    right:'12px'
                  }}/>
                  <Box sx={{
                    position:'absolute',
                    borderRadius:'16px',
                    width:'100%',
                    height:'100%',
                    top:'0px',
                    left:'0px',
                    bottom:'0px',
                    right:'0px',
                    background:'linear-gradient(90deg, rgba(255,255,255,1) , rgba(255,255,255,0))'
                  }} />
                <Typography variant="h6" element="p" sx={{
                  textAlign:"left",
                  fontFamily: "NotoSansKR",
                  fontWeight:700,
                  position:'relative',
                  fontSize:'16px',
                  whiteSpace: "pre-line",
                  mb:'18px'}}>
                  자주 묻는 질문
                  <br /> FAQ
                </Typography>
                <Typography variant="h6" element="p" sx={{
                  textAlign:"left",
                  fontFamily: "NotoSansKR",
                  fontWeight:300,
                  fontSize:'13px',
                  position:'relative',
                  color:'#666666',
                  whiteSpace: "pre-line"}}>
                  문의 전 빠르게 확인하세요
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              md={12}>
              <Typography variant="h6" element="p" sx={{
                textAlign:"left",
                fontFamily: "NotoSansKR",
                fontWeight:700,
                position:'relative',
                whiteSpace: "pre-line",
                mt:'12px',
                mb:'24px'}}>
                공지사항
              </Typography>
              <NoticeTableView />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardPage;
