import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import { teal } from '@mui/material/colors';

import {
  Button,
  IconButton,
  Grid
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey
} from '@mui/material/colors';


import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

import axios from "axios";

function FAQPage(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Header {...props} />
      <Box sx={{height:'auto', backgroundColor:'black'}}>
        <Box sx={{width:'100%', height:'60px'}} />
        <Toolbar sx={{position:'relative', height:'100%'}}>
        </Toolbar>
      </Box>
      <Container>
        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{minHeight:'100vh'}}
            alignItems="center">
          <Grid item md={6}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                textAlign:'left',
                color:lightBlue[600]
              }}>
              아직도 비싼 오피스를 사용 중이신가요?
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                fontSize:'24px',
                textAlign:'left',
                mb:'24px',
                color:blueGrey[800]
              }}>
              서울 최저가 공유 오피스
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:500,
                fontSize:'18px',
                textAlign:'left',
                color:blueGrey[800]
              }}>
              Gigto Space는 다른 임대형 오피스 및 공유 오피스 대비 최저가로 운영하고 있습니다.
              아직도 비싼 가격에 오피스를 임대하고 계신가요?
              이제는 Gigto Space를 통해서 합리적인 가격으로 오피스를 사용하세요.
            </Typography>
          </Grid>
          <Grid item md={6}>

          </Grid>
          </Grid>
        </Box>

        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{minHeight:'100vh'}}
            alignItems="center">
          <Grid item md={6}>

          </Grid>
          <Grid item md={6}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                textAlign:'left',
                color:lightBlue[600]
              }}>
              비상주 오피스 및 소규모 사업자도 가능
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                fontSize:'24px',
                textAlign:'left',
                mb:'24px',
                color:blueGrey[800]
              }}>
              비상주 및 1인, 소규모 사업자를 위한 오피스
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:500,
                fontSize:'18px',
                textAlign:'left',
                color:blueGrey[800]
              }}>
              오픈 되어 있는 개인 사무 공간 뿐만 아니라, 별도의 공간으로 분리되어 있는 소규모 사무 공간으로
              처음 사업을 시작하시는 스타트업에게 효율적인 형태를 지니고 있습니다.
            </Typography>
          </Grid>
          </Grid>
        </Box>

        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{minHeight:'100vh'}}
            alignItems="center">
          <Grid item md={6}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                textAlign:'left',
                color:lightBlue[600]
              }}>
              주차 및 회의는 신경쓰지 마세요
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                fontSize:'24px',
                textAlign:'left',
                mb:'24px',
                color:blueGrey[800]
              }}>
              넉넉한 회의 공간 및 언제든 이용 가능한 주차시설
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:500,
                fontSize:'18px',
                textAlign:'left',
                color:blueGrey[800]
              }}>
              Gigto Space에는 1개의 대형 회의실 및 3개의 소형 회의실로 사용 인원 대비 넉넉한 회의실을 보유하고 있으며,
              이외에도 충분한 주차공간을 통해, 24시간 언제든 주차가 가능합니다. 더 이상 부가적인 시설 부족으로 마음 고생 하지 마세요
            </Typography>
          </Grid>
          <Grid item md={6}>

          </Grid>
          </Grid>
        </Box>

        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{minHeight:'100vh'}}
            alignItems="center">
          <Grid item md={6}>

          </Grid>
          <Grid item md={6}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                textAlign:'left',
                color:lightBlue[600]
              }}>
              제조업 및 유통 등에 특화된 오피스
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                fontSize:'24px',
                textAlign:'left',
                mb:'24px',
                color:blueGrey[800]
              }}>
              문래동에 위치하여, 각종 유통 상가와 접근성이 뛰어난 오피스
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:500,
                fontSize:'18px',
                textAlign:'left',
                color:blueGrey[800]
              }}>
              도보로 도림천역(2호선) 10분, 문래역(2호선) 15분의 접근성을 가지고 있으며, 문래 창작촌 및 구로 디지털 단지,
              가산 디지털 단지와 가까운 서울 서부 권역으로 제조업 및 유통 사업을 중점으로 하는 스타트업에게 유리한 위치를 가지고 있습니다.
            </Typography>
          </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

export default FAQPage;
