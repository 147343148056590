import React from 'react';
import { Routes, Route, Switch, Navigate, useNavigate, useLocation } from 'react-router-dom';
import useToken from '../utils/useToken'
import axios from 'axios';

import {
  DashboardPage,

  MeetingManagePage,
  OfficeManagePage,
  PrintManagePage,
  SettingPage,
  ServiceManagePage,

  QueueLayout,
  SignedLayout,
  SignedSubLayout,

} from '../containers'

export default function SignedRoute(props){

  const { token, removeToken, setToken } = useToken();
  const navigate = useNavigate();
  const { state } = useLocation();
  // errorComposer will compose a handleGlobally function
  const errorComposer = (error) => {

      return () => {
          const statusCode = error.response ? error.response.status : null;
          if (statusCode === 404) {

          }

          if (statusCode === 401) {
            props.onRemoveToken();
          }
      }
  }

  axios.interceptors.response.use(undefined, function (error) {
      error.handleGlobally = errorComposer(error);

      return Promise.reject(error);
  })

  if (state) {
      navigate(state);
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/u/dashboard" />} />
      <Route path="*" element={<Navigate to="/u/dashboard" />} />
      <Route path='/u/dashboard' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken} menu="dashboard">
            <DashboardPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/u/meeting' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken} menu="meetingRoom">
            <MeetingManagePage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/u/office' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken} menu="contract">
            <OfficeManagePage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/u/service' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken} menu="service">
            <ServiceManagePage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/u/setting' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken} menu="setting">
            <SettingPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      {/*<Route path='/my/info' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <MyInfoPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/host/list' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <HostListPage hiPage={"HOST_LIST"} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/crawler/list' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <CrawlerListPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/crawler/item/:id' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <CrawlerItemPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/crawler/add' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <CrawlerAddPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/product/list' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <ProductListPage hiPage={"PRODUCT_LIST"} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/product/my' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <ProductMyPage hiPage={"PRODUCT_MY"} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/product/item/:id' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <ProductPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/storage' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <StoragePage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/storage/list' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <StorageListPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/storage/add/file' element={
        <QueueLayout>
          <SignedSubLayout title={'파일로 재고 추가하기'} removeToken={removeToken}>
            <StoragFileAddPage token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/auth/change' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <ChangePasswordPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/auth/account' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <MyInfoPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/do/product/list' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DOProductListPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/do/product/recommend' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DOProductRecommendPage {...props} hiPage={"RECOMMEND_LIST"} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/do/product/info/:id' element={
        <QueueLayout>
          <SignedSubLayout title={'Target 상품 정보'} removeToken={removeToken}>
            <DOProductInfoPage hiPage={"PRODUCT_INFO"} token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/do/product/info/:id/change' element={
        <QueueLayout>
          <SignedSubLayout title={'Target 상품 정보 수정'} removeToken={removeToken}>
            <DOProductChangePage hiPage={"PRODUCT_CHANGE"} token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/do/product/match' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DOProductMatchPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/do/product/mails' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DOMailPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/do/product/match/:id' element={
        <QueueLayout>
          <SignedSubLayout title={'Agent 파일 분석 결과'} removeToken={removeToken}>
            <DOProductExcelPage token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/do/product/add' element={
        <QueueLayout>
          <SignedSubLayout title={'Daily order 상품 추가하기'} removeToken={removeToken}>
            <DOProductAddPage token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/do/product/add/file' element={
        <QueueLayout>
          <SignedSubLayout title={'Daily order 상품 엑셀로 추가하기'} removeToken={removeToken}>
            <DOProductExcelAddPage token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/do/agent/list' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DOAgentPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/do/agent/add' element={
        <QueueLayout>
          <SignedSubLayout title={'Daily order 에이전트 추가하기'} removeToken={removeToken}>
            <DOAgentAddPage token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/do/accounts' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DOUserAccountPage token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/do/file/:file_key' element={
        <QueueLayout>
          <SignedSubLayout title={'Daily order 파일'} removeToken={removeToken}>
            <DOFilePage token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/do/review/:file_key' element={
        <QueueLayout>
          <SignedSubLayout title={'Daily order 리뷰하기'} removeToken={removeToken}>
            <DOReviewPage token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/do/statistics' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DOStatisticsPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/do/kream_statistics' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DOKreamStatisticsPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/storage' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <StoragePage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/tools/image_generator' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <ImageGeneratorPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/tools/logs' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <AccessLogsPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/tools/image_generator/add' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <ImageGeneratorAddPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/tools/image_generator/thumbnail' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <ImageGeneratorOnlyPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/setting/brands' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <BrandManagePage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/setting/brands/add' element={
        <QueueLayout>
          <SignedSubLayout title={'새로운 브랜드 추가하기'} removeToken={removeToken}>
            <BrandNewPage token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/setting/brands/:brand_id' element={
        <QueueLayout>
          <SignedSubLayout title={'브랜드 정보 수정하기'} removeToken={removeToken}>
            <BrandEditPage token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />*/}
    </Routes>
  )
}
