import React from 'react';

import Typography  from '@mui/material/Typography';

export default function TH1(props){
  return (
    <Typography
      sx={{
        ...props.sx,
        fontFamily: "NotoSansKR",
        fontWeight:800,
        fontSize:'28px',
        textAlign:'left',
        color:'black'
      }}>
      {props.children}
    </Typography>
  );
}
