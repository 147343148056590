import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Container,
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Paper,
  Stack,
  Typography
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

function MeetingRoomView(props){
  return (
    <Box sx={{width:'100%', position:'relative'}}>
      <Box sx={{width:'100%',
        paddingBottom:'60%',
        position:'relative',
        mb:'8px'}}>
        <img
          style={{
            position:'absolute',
            width:'100%',
            height:'100%',
            borderRadius:'16px',
            top:0, left:0, right:0, bottom:0,
            objectFit:'cover'
          }}
          src={props.src}/>
      </Box>
      <Typography element="p" sx={{
        textAlign:"left",
        fontFamily: "NotoSansKR",
        textSize:'14px',
        fontWeight:500}}>
        {props.name}
      </Typography>
      <Typography element="p" sx={{
        textAlign:"left",
        fontFamily: "NotoSansKR",
        fontSize:'13px',
        color:'#222222',
        fontWeight:500}}>
        최대 {props.max}명
      </Typography>
      <Typography element="p" sx={{
        textAlign:"left",
        fontFamily: "NotoSansKR",
        fontSize:'12px',
        color:'#777777',
        fontWeight:300}}>
        {props.description}
      </Typography>
    </Box>
  );
}

export default MeetingRoomView;
